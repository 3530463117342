import React, { ReactNode, useState } from "react"
import { ITheme, makeStyles, createStyles } from "@chainsafe/common-theme"
import { Typography } from "@chainsafe/common-components";
import clsx from "clsx"
import { DirectionalRightIcon } from "@chainsafe/common-components"

const useStyles = makeStyles(
  ({ breakpoints, animation, constants, palette, overrides }: ITheme) =>
    createStyles({
      // JSS in CSS goes here
      root: {
        position: "relative",
        "&.basic": {
          border: `1px solid ${palette.additional["gray"][8]}`,
          ...overrides?.ExpansionPanel?.basic,
        },
        "&.borderless": {
          ...overrides?.ExpansionPanel?.borderless,
          [breakpoints.down('xl')]: {
            width: "100%",
          },
          [breakpoints.up('xl')]: {
            left: "50%",
            transform: "translate(-50%)",
            textAlign: "center",
          }
        },
        ...overrides?.ExpansionPanel?.root,
      },
      icon: {
        "& svg": {
          width: constants.generalUnit * 2,
          height: constants.generalUnit * 3.5,
          fill: palette.additional["yellow"][3],
          transitionDuration: `${animation.transform}ms`,
          [breakpoints.down('md')]: {
            width: constants.generalUnit * 1.5,
            height: constants.generalUnit * 3,
          },
          [breakpoints.down('sm')]: {
            width: constants.generalUnit * 1.5,
            height: constants.generalUnit * 2,
          }
        },
        ...overrides?.ExpansionPanel?.icon,
      },
      heading: {
        backgroundColor: "transparent",
        padding: `${constants.generalUnit * 1.5}px ${constants.generalUnit * 2
          }px`,
        color: palette.additional["gray"][9],
        cursor: "pointer",
        "&.basic": {
          backgroundColor: palette.additional["gray"][2],
          ...overrides?.ExpansionPanel?.heading?.basic?.root,
          "&.active": {
            ...overrides?.ExpansionPanel?.heading?.basic?.active,
          },
        },
        "&.borderless": {
          ...overrides?.ExpansionPanel?.heading?.borderless?.root,
          "&.active": {
            ...overrides?.ExpansionPanel?.heading?.borderless?.active,
          },
        },
        "&.active": {
          "& svg": {
            transform: "rotateZ(90deg)",
          },
          ...overrides?.ExpansionPanel?.heading?.active,
        },
        ...overrides?.ExpansionPanel?.heading?.root,
      },
      customHeading: {
        fontSize: "40px",
        fontWeight: "normal",
        letterSpacing: "2px",
        color: palette.additional["gray"][3],
        marginLeft: constants.generalUnit * 2,
        [breakpoints.down('md')]: {
          fontSize: "28px",
          lineHeight: "36px",
        },
        [breakpoints.down('sm')]: {
          fontSize: "20px",
          lineHeight: "28px",
        },
        "&.schedule": {
          fontFamily: "vcr",
        },
        "&.faq": {
          fontFamily: "Archivo",
          fontWeight: "bold",
          fontSize: "35px",
          marginLeft: constants.generalUnit,
          [breakpoints.down('md')]: {
            fontSize: "28px",
          },
          [breakpoints.down('sm')]: {
            fontSize: "20px",
          },
        },
      },
      content: {
        overflow: "hidden",
        color: palette.additional["gray"][1],
        height: 0,
        padding: `0 ${constants.generalUnit * 2}px`,
        transitionDuration: `${animation.transform}ms`,
        textAlign: "left",
        // opacity: 0,
        "&.basic": {
          backgroundColor: palette.common.white.main,
          borderTop: `0px solid ${palette.additional["gray"][5]}`,
          "&.active": {
            borderTop: `1px solid ${palette.additional["gray"][5]}`,
            ...overrides?.ExpansionPanel?.content?.basic?.active,
          },
          ...overrides?.ExpansionPanel?.content?.basic?.root,
        },
        "&.active": {
          "&.faq": {
            borderTop: "none",
            padding: `${constants.generalUnit}px ${constants.generalUnit}px`,
          },
          borderTop: `1px solid ${palette.additional["gray"][7]}`,
          padding: `${constants.generalUnit * 2}px ${constants.generalUnit * 2
            }px`,
          opacity: 1,
          height: "auto",
          ...overrides?.ExpansionPanel?.content?.active,
        },
        ...overrides?.ExpansionPanel?.content?.root,
      },
    }),
)

export interface IExpansionPanelProps {
  header: string
  children?: ReactNode | ReactNode[] | null
  active?: boolean
  variant?: "basic" | "borderless"
  toggle?: (state: boolean) => void
  className?: string
  landingVariant?: "schedule" | "faq"
}

const ExpansionPanel: React.FC<IExpansionPanelProps> = ({
  children,
  header,
  variant = "basic",
  toggle,
  active,
  landingVariant = "schedule",
}: IExpansionPanelProps) => {
  const classes = useStyles()
  const [activeInternal, setActive] = useState(active)
  const handleToggle = () => {
    toggle && toggle(!activeInternal)
    setActive(!activeInternal)
  }
  return (
    <div className={clsx(classes.root, variant)}>
      <section
        onClick={() => handleToggle()}
        className={clsx(classes.heading, variant, {
          "active": active !== undefined ? active : activeInternal,
        })}
      >
        <DirectionalRightIcon className={classes.icon} />
        <Typography variant="h1" className={clsx(classes.customHeading, landingVariant)}>{header}</Typography>
      </section>
      <section
        className={clsx(classes.content, variant, landingVariant, {
          "active": active !== undefined ? active : activeInternal,
        })}
      >
        {children}
      </section>
    </div>
  )
}

export default ExpansionPanel
