import React from 'react'
import Section from "../Modules/Section";
import { createStyles, ITheme, makeStyles } from "@chainsafe/common-theme";
import { Grid } from "@chainsafe/common-components";


const useStyles = makeStyles(({ breakpoints, palette, zIndex }: ITheme) => {

  return createStyles({
    wrapper: {
      maxWidth: "2560px",
      zIndex: zIndex?.layer3,
      position: "relative",
      minHeight: "50vh",
      marginTop: "20vh",
      marginBottom: "5vh",
      [breakpoints.up('xl')]: {
        display: "flex",
        alignItems: "center",
        maxWidth: "none",
        marginBottom: "none",
        marginTop: "10vh",
        minHeight: "40vh",
      },
      [breakpoints.up(3800)]: {
        minHeight: "40vh",
      },
      [breakpoints.down('md')]: {
        maxWidth: "none",
        marginTop: "10vh",
      },
    },

    text: {
      color: palette.additional["gray"][3],
      fontSize: "1.2em",
      lineHeight: "1.5em",
      margin: "0.5em 0",
      maxWidth: "400px",
      [breakpoints.down('md')]: {
        width: "90%",
        maxWidth: "none",
        margin: "1rem",
      },
      [breakpoints.up('xl')]: {
        maxWidth: "500px",
      },
      [breakpoints.up('3800')]: {
        maxWidth: "700px",
        fontSize: "1.9em",
        lineHeight: "1.9em",
      },
    },
  })
})

const About: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Section>
        <Grid container alignItems="center" justifyContent="center">
          <Grid container xs={12} justifyContent="center">
            <div >
              <p className={classes.text}>
                CSCON[0] is a chance to refuel, learn from and meet the thinkers, developers, and leaders working to bring Web3 to a mainstream audience.
              </p>
              <p className={classes.text}>
                ChainSafe is proud to be hosting our first virtual conference showcasing many of the exciting and innovative projects that we're honoured to be apart of.
              </p>
              <p className={classes.text}>
                Over the course of 3 days from December 2-4 2020, we will offer the community a place to come together, share ideas and allow for new connections to be developed.
              </p>
            </div>
          </Grid>
        </Grid>

      </Section>
    </div >

  )
}

export default About