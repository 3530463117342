import React from 'react'
import Section from "../Modules/Section";
import { createStyles, ITheme, makeStyles } from "@chainsafe/common-theme";
import { Typography, Grid } from "@chainsafe/common-components";
import { dec2Speakers, dec3Speakers, dec4Speakers } from "./speakerInfo";
import underline from "./svg/underline.svg";
import ExpansionPanel from "../Modules/ExpansionPanel";
import dottedbox from "./svg/dotted1.svg";


const useStyles = makeStyles(({ breakpoints, palette, zIndex, constants }: ITheme) => {

  return createStyles({
    container: {
      maxWidth: "2560px",
      margin: "0 5rem",
      paddingTop: "2rem",
      minHeight: "50vh",
      [breakpoints.up('xl')]: {
        maxWidth: "100%",
        margin: 0,
        minHeight: 0,
        display: "flex",
        alignItems: "baseline"
      },
      [breakpoints.down('sm')]: {
        maxWidth: "100%",
        margin: 0,
      },
    },
    textContainer: {
      position: "relative",
      zIndex: zIndex?.layer3,
      margin: "4rem 0",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "& > span": {
        marginLeft: "1rem",
      },
      [breakpoints.down('lg')]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: 0,
        "& > span": {
          fontSize: "40px",
          margin: 0,
        },
      },
      [breakpoints.down('1280')]: {
        alignItems: "flex-start",
      },
      [breakpoints.up('xl')]: {
        alignItems: "center",
      },
    },
    titleUnderline: {
      maxWidth: "300px",
      marginLeft: "1rem",
      [breakpoints.down('lg')]: {
        maxWidth: "180px",
        margin: 0,
      },
      [breakpoints.down('1280')]: {
        maxWidth: "300px",
      }
    },
    panelsWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      [breakpoints.up('xl')]: {
        display: "flex",
        alignItems: "baseline",
        position: "relative",
        left: "50%",
        transform: "translate(-50%)",
      },
      [breakpoints.down('md')]: {
        margin: 0,
      }
    },
    panelWrapper: {
      margin: 0,
      [breakpoints.up('xl')]: {
        border: "1px solid gray",
      }
    },
    eventWrapper: {
      width: "100%",
      display: "grid",
      margin: "0 1rem",
      gridTemplateColumns: "20% 50% 40%",
      [breakpoints.down('lg')]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        margin: "2rem 0",
        borderBottom: `1px solid ${palette.additional["gray"][9]}`
      },
      [breakpoints.up('xl')]: {
        gridTemplateColumns: "20% 60% 30%",
      },
      '& > div': {
        [breakpoints.up('lg')]: {
          paddingBottom: "2rem",
        },
        [breakpoints.down('lg')]: {
          paddingBottom: "1rem",
        },
      },
    },
    titleWrapper: {
      justifySelf: "start",
      [breakpoints.up('lg')]: {
        alignSelf: "start",
        maxWidth: "500px",
      }
    },
    timeWrapper: {
      maxWidth: "300px",
      [breakpoints.down('md')]: {
        maxWidth: "100%",
      },
      [breakpoints.up('lg')]: {
        alignSelf: "center",
      }
    },
    speakerWrapper: {
      maxWidth: "60%",
      [breakpoints.up('lg')]: {
        alignSelf: "center",
      },
      margin: 0,
      height: "auto",
    },
    text: {
      fontFamily: "Archivo",
      color: palette.additional["gray"][4],
    },
    talkTitleText: {
      fontFamily: "Archivo",
      textAlign: "left",
      color: palette.additional["gray"][3],
    },
    dottedBox: {
      [breakpoints.down('md')]: {
        display: "none",
      },
      [breakpoints.up('xl')]: {
        display: "none",
      },
    },
  })
})

const Schedule: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container} id="schedule">
      <Section>
        <Grid container xs={12} justifyContent="center" alignItems="center">
          <Grid item className={classes.textContainer}>
            <Typography variant="h1" >Schedule</Typography>
            <img src={underline} className={classes.titleUnderline} alt="Decorative pixel underline" />
          </Grid>
          <Grid item alignItems="flex-end" className={classes.dottedBox}>
            <img src={dottedbox} alt="a box made out of dots" />
          </Grid>
        </Grid>
        <div className={classes.panelsWrapper}>
          <ExpansionPanel header="December 2nd 2020" variant="borderless" landingVariant="schedule" className={classes.panelWrapper}>
            {dec2Speakers.map((speaker, index) => (
              <div className={classes.eventWrapper} key={index}>
                <div className={classes.timeWrapper}>
                  <p className={classes.text}>{speaker.talkTime}</p>
                </div>
                <div className={classes.titleWrapper}>
                  <Typography variant="h3" className={classes.talkTitleText}><strong>{speaker.talkTitle}</strong></Typography>
                </div>
                <div className={classes.speakerWrapper}>
                  <p className={classes.text}>{speaker.name}</p>
                  <p className={classes.text}>{speaker.title}, {speaker.companyName}</p>
                </div>
              </div>
            ))}
          </ExpansionPanel>
          <ExpansionPanel header="December 3rd 2020" variant="borderless" className={classes.panelWrapper}>
            {dec3Speakers.map((speaker, index) => (
              <div className={classes.eventWrapper} key={index}>
                <div className={classes.timeWrapper}>
                  <p className={classes.text}>{speaker.talkTime}</p>
                </div>
                <div className={classes.titleWrapper}>
                  <Typography variant="h3" className={classes.talkTitleText}><strong>{speaker.talkTitle}</strong></Typography>
                </div>
                <div className={classes.speakerWrapper}>
                  <p className={classes.text}>{speaker.name}</p>
                  <p className={classes.text}>{speaker.title}, {speaker.companyName}</p>
                </div>
              </div>
            ))}
          </ExpansionPanel>
          <ExpansionPanel header="December 4th 2020" variant="borderless" className={classes.panelWrapper}>
            {dec4Speakers.map((speaker, index) => (
              <div className={classes.eventWrapper} key={index}>
                <div className={classes.timeWrapper}>
                  <p className={classes.text}>{speaker.talkTime}</p>
                </div>
                <div className={classes.titleWrapper}>
                  <Typography variant="h3" className={classes.talkTitleText}><strong>{speaker.talkTitle}</strong></Typography>
                </div>
                <div className={classes.speakerWrapper}>
                  <p className={classes.text}>{speaker.name}</p>
                  <p className={classes.text}>{speaker.title}, {speaker.companyName}</p>
                </div>
              </div>
            ))}
          </ExpansionPanel>
        </div>
      </Section >
    </div >
  )
}

export default Schedule


