export const speakers = [
  {
    "name": "Aidan Hyman",
    "companyName": "ChainSafe",
    "headshotUrl": "/headshots/Aidan.png",
  },
  {
    "name": "Austin Abell",
    "companyName": "ChainSafe",
    "headshotUrl": "/headshots/Austin.png",
  },
  {
    "name": "Amer Ameen",
    "companyName": "ChainSafe",
    "headshotUrl": "/headshots/Amer.png",
  },
  {
    "name": "Arianne Flemming",
    "companyName": "Informal Systems",
    "headshotUrl": "/headshots/Arianne.jpeg",
  },
  {
    "name": "Lorena Mance",
    "companyName": "ChainSafe",
    "headshotUrl": "/headshots/Lorena.jpeg",
  },
  {
    "name": "Ken Ng",
    "companyName": "Ethereum Foundation",
    "headshotUrl": "/headshots/Ken.jpeg",
  },
  {
    "name": "James Prestwich",
    "companyName": "Celo",
    "headshotUrl": "/headshots/James.png",
  },
  {
    "name": "Emin Gun Sirer",
    "companyName": "Ava Labs",
    "headshotUrl": "/headshots/Egs.png",
  },
  {
    "name": "Lucas Vogelsang",
    "companyName": "Centrifuge",
    "headshotUrl": "/headshots/Lucas.png",
  },

  {
    "name": "David Ansermino",
    "companyName": "ChainSafe",
    "headshotUrl": "/headshots/David.jpeg",
  },
  {
    "name": "Eric Tu",
    "companyName": "ChainSafe",
    "headshotUrl": "/headshots/Eric.png",
  },
  {
    "name": "Remington Ong",
    "companyName": "Fenbushi Capital",
    "headshotUrl": "/headshots/Remington.png",
  },
  {
    "name": "Emily Guidry",
    "companyName": "Ethereum Foundation",
    "headshotUrl": "/headshots/default.png",
  },
  {
    "name": "Peng Zhong",
    "companyName": "Tendermint",
    "headshotUrl": "/headshots/Peng.jpeg",
  },
  {
    "name": "Polina Aladina",
    "companyName": "ChainSafe",
    "headshotUrl": "/headshots/Polina.png",
  },
  {
    "name": "Mike Goelzer",
    "companyName": "Protocol Labs",
    "headshotUrl": "/headshots/Mike.jpg",
  },
  {
    "name": "Bassem El Remesh",
    "companyName": "ChainSafe",
    "headshotUrl": "/headshots/Bassem.png",
  },
  {
    "name": "Stu Peters",
    "title": "VP Projects",
    "companyName": "ChainSafe",
    "headshotUrl": "/headshots/Stu.png",
  },
  {
    "name": "Peter Mauric",
    "companyName": "Parity Technologies",
    "headshotUrl": "/headshots/Peter.jpg",
  },
  {
    "name": "Jelena Djuric",
    "companyName": "Informal Systems",
    "headshotUrl": "/headshots/Jelena.jpeg",
  },
  {
    "name": "Jack Platts",
    "companyName": "Hypersphere",
    "headshotUrl": "/headshots/Jack.png",
  },
  {
    "name": "Joe Petrowski",
    "companyName": "Parity Technologies",
    "headshotUrl": "/headshots/Joe.jpg",
  },
  {
    "name": "Joseph Lubin",
    "companyName": "ConsenSys",
    "headshotUrl": "/headshots/Joseph.jpeg",
  },
  {
    "name": "Praneeth Srikanti",
    "companyName": "ConsenSys Labs",
    "headshotUrl": "/headshots/Praneeth.png",
  },
  {
    "name": "Robert Habermeier",
    "companyName": "Parity Technologies",
    "headshotUrl": "/headshots/Rob.jpeg",
  },
  {
    "name": "Arjun Bhuptani",
    "companyName": "Connext",
    "headshotUrl": "/headshots/Arjun.jpg",
  },
  {
    "name": "David Hawig",
    "companyName": "Web3 Foundation",
    "headshotUrl": "/headshots/DavidH.png",
  },
  {
    "name": "Elizabeth Binks",
    "companyName": "ChainSafe",
    "headshotUrl": "/headshots/Elizabeth.png",
  },
  {
    "name": "Billy Rennekamp",
    "companyName": "Interchain Foundation, Clovers Network",
    "headshotUrl": "/headshots/Billy.png",
  },
  {
    "name": "Federico Kunze Küllmer‏",
    "companyName": "ChainSafe",
    "headshotUrl": "/headshots/Federico.png",
  },
  {
    "name": "Ethan Buchman",
    "companyName": "Informal Systems, Cosmos",
    "headshotUrl": "/headshots/Ethan.jpg",
  },
  {
    "name": "Cayman Nava",
    "companyName": "ChainSafe",
    "headshotUrl": "/headshots/Cayman.jpg",
  },
  {
    "name": "Brandon Iles",
    "companyName": "Ampleforth",
    "headshotUrl": "/headshots/Brandon.jpeg",
  },
  {
    "name": "Drew Stone",
    "companyName": "Edgeware",
    "headshotUrl": "/headshots/Drew.png",
  },
  {
    "name": "Danny Ryan",
    "companyName": "Ethereum Foundation",
    "headshotUrl": "/headshots/Danny.jpg",
  },
  {
    "name": "Marek Olszewski",
    "companyName": "Celo",
    "headshotUrl": "/headshots/Marek.png",
  },
  {
    "name": "Molly Mackinlay",
    "companyName": "Protocol Labs",
    "headshotUrl": "/headshots/Molly.png",
  },
  {
    "name": "Greg Markou",
    "companyName": "ChainSafe",
    "headshotUrl": "/headshots/Greg.png",
  },
  {
    "name": "Juan Benet",
    "title": "Founder & CEO",
    "companyName": "Protocol Labs",
    "headshotUrl": "/headshots/Juan.jpg",
  },
  {
    "name": "Raúl Kripalani",
    "companyName": "Protocol Labs",
    "headshotUrl": "/headshots/Raul.jpg",
  },
  {
    "name": "Miguel Hervas",
    "companyName": "Centrifuge",
    "headshotUrl": "/headshots/Miguel.jpg",
  },
  {
    "name": "Pooja Shah",
    "companyName": "Protocol Labs",
    "headshotUrl": "/headshots/Pooja.png",
  },
  {
    "name": "Terry Culver",
    "companyName": "ETC Labs",
    "headshotUrl": "/headshots/Terry.png",
  },
  {
    "name": "Dieter Fishbein",
    "companyName": "Web3 Foundation",
    "headshotUrl": "/headshots/Dieter.png",
  },
  {
    "name": "Colin Schwarz",
    "companyName": "ChainSafe",
    "headshotUrl": "/headshots/ColinS.png",
  },

  {
    "name": "Priom Chowdhury",
    "companyName": "ChainSafe",
    "headshotUrl": "/headshots/Priom.png",
  },
  {
    "name": "Cindy Chau",
    "title": "Product Designer",
    "companyName": "ChainSafe",
    "headshotUrl": "/headshots/Cindy.jpg",
  },
]

export const dec2Speakers = [
  {
    "name": "Aidan Hyman",
    "title": "CEO",
    "companyName": "ChainSafe",
    "talkTime": "10:00-10:30am",
    "talkTitle": "Welcome to CSCON[0] and The Journey That Got Us Here"
  },
  {
    "name": "Elizabeth Binks",
    "title": "Tech Lead",
    "companyName": "ChainSafe",
    "talkTime": "10:30-11:00am",
    "talkTitle": "Gossamer: A Polkadot Journey, in Go",
  },
  {
    "name": "Billy Rennekamp",
    "title": "Grants Manager & Founder",
    "companyName": "Interchain Foundation, Clovers Network",
    "talkTime": "11:00-11:30am",
    "talkTitle": "Nerd Night by Cosmos",
  },
  {
    "name": "Emin Gun Sirer",
    "title": "Founder & CEO",
    "companyName": "Ava Labs",
    "talkTime": "11:30-12:00pm",
    "talkTitle": "Open and Programmable Finance with Avalanche"
  },
  {
    "name": "Ethan Buchman",
    "title": "CEO & Co-Founder",
    "companyName": "Informal Systems, Cosmos",
    "talkTime": "12:00-12:30pm",
    "talkTitle": "Tbd"
  },
  {
    "name": "Federico Kunze",
    "title": "Tech Lead",
    "companyName": "ChainSafe",
    "talkTime": "12:30-1:00pm",
    "talkTitle": "Ethermint: Use Cases for a Fast Finality and Interoperable EVM"
  },
  {
    "name": "Danny Ryan",
    "title": "Eth2 Tech Lead",
    "companyName": "Ethereum Foundation",
    "talkTime": "1:00-1:30pm",
    "talkTitle": "Eth2 Beacon Chain Launch and Beyond"
  },
  {
    "name": "Cayman Nava",
    "title": "Tech Lead",
    "companyName": "ChainSafe",
    "talkTime": "1:30-2:00pm",
    "talkTitle": "Lodestar"
  },
  {
    "name": "Greg Markou",
    "title": "CTO",
    "companyName": "ChainSafe",
    "talkTime": "2:00-2:30pm",
    "talkTitle": "The Future of Web3.js"
  },
  {
    "name": "Marek Olszewski",
    "title": "Founder",
    "companyName": "Celo",
    "talkTime": "2:30-3:00pm",
    "talkTitle": "Tbd"
  },
  {
    "name": "Molly Mackinlay",
    "title": "Sr. Product Manager",
    "companyName": "Protocol Labs",
    "talkTime": "3:00-3:30pm",
    "talkTitle": "IPFS & libp2p in 2020"
  },
  {
    "name": "Polina Aladina",
    "title": "Project Manager",
    "companyName": "ChainSafe",
    "talkTitle": "ChainBridge 101",
    "talkTime": "3:30-4:00pm",
  },
  {
    "name": "Austin Abell",
    "title": "Tech Lead",
    "companyName": "ChainSafe",
    "talkTime": "4:00-4:30pm",
    "talkTitle": "Forest: Building a Rust Filecoin implementation"
  },
  {
    "name": "Eric Tu",
    "title": "Systems Engineer",
    "companyName": "ChainSafe",
    "talkTime": "4:30-5:00pm",
    "talkTitle": "Big Chungus Mining Corp"
  },
]
export const dec3Speakers = [
  {
    "name": "Bassem El Remesh",
    "title": "VP Operations",
    "companyName": "ChainSafe",
    "talkTime": "9:30-10:00am",
    "talkTitle": "Challenges of and Approaches to Cryptoaccounting"
  },
  {
    "name": "David Ansermino",
    "title": "VP Engineering",
    "companyName": "ChainSafe",
    "talkTime": "10:00-10:30am",
    "talkTitle": "Filecoin Retrieval Markets"
  },
  {
    "name": "Remington Ong",
    "title": "Partner",
    "companyName": "Fenbushi Capital",
    "talkTime": "10:30-11:00am",
    "talkTitle": "Tbd"
  },
  {
    "name": "Amer Ameen",
    "title": "VP Project Management",
    "companyName": "ChainSafe",
    "talkTime": "11:00-11:30am",
    "talkTitle": "The Unsung Leadership Skill Developed by Hostage Negotiators & Suicide Hotlines: Active Listening"
  },
  {
    "name": "Lucas Vogelsang",
    "title": "Co-Founder & CEO",
    "companyName": "Centrifuge",
    "talkTime": "11:30-12:00pm",
    "talkTitle": "Tbd"
  },
  {
    "name": "Brandon Iles",
    "title": "Co-Founder",
    "companyName": "Ampleforth",
    "talkTime": "12:00-12:30pm",
    "talkTitle": "Intro to Ampleforth, Ampleforth’s multichain vision, and how Ampleforth works with Chainsafe to execute on it"
  },
  {
    "name": "Ken Ng & Emily Guidry",
    "title": "Head of Grants",
    "companyName": "Ethereum Foundation",
    "talkTime": "12:30-1:00pm",
    "talkTitle": "ESP For You and Me"
  },
  {
    "name": "Peng Zhong",
    "title": "CEO",
    "companyName": "Tendermint",
    "talkTime": "1:00-1:30pm",
    "talkTitle": "A Million Blockchains"
  },
  {
    "name": "Drew Stone",
    "title": "Tech",
    "companyName": "Edgeware",
    "talkTime": "1:30-2:00pm",
    "talkTitle": "Reputation Systems"
  },
  {
    "name": "Arjun Bhuptani",
    "title": "Founder",
    "companyName": "Connext",
    "talkTitle": "Connext: Instant, Cross-Chain Communication for Ethereum L2s",
    "talkTime": "2:00-2:30pm",
  },
  {
    "name": "James Prestwich",
    "title": "Builder",
    "companyName": "Celo",
    "talkTime": "2:30-3:00pm",
    "talkTitle": "Cross-Chain Communication"
  },
  {
    "name": "Raúl Kripalani",
    "title": "Tech Lead",
    "companyName": "Protocol Labs",
    "talkTime": "3:00-3:30pm",
    "talkTitle": "Testground: A Platform for Testing P2P and Distributed Systems at Scale"
  },
  {
    "name": "Rob Habermeier",
    "title": "Co-Founder Polkadot",
    "companyName": "Parity Technologies",
    "talkTime": "3:30-4:00pm",
    "talkTitle": "Tbd"
  },
  {
    "name": "Jelena Djuric",
    "title": "Operations Manager",
    "companyName": "Informal Systems",
    "talkTime": "4:00-4:30pm",
    "talkTitle": "The New World Struggles to be Born: Coordinating In a Time of Crisis"
  },
  {
    "name": "Lorena Mance & Cindy Chau",
    "title": "Legal Counsel",
    "companyName": "ChainSafe",
    "talkTime": "4:30-5:00pm",
    "talkTitle": "An Overview On Challenges Re: Fraud in Crypto/Securities"
  },
];

export const dec4Speakers = [
  {
    "name": "Terry Culver",
    "title": "CEO",
    "companyName": "ETC Labs",
    "talkTitle": "Where the Wild Things Are: Cross Chain Collaboration",
    "talkTime": "10:00-10:30am"

  },
  {
    "name": "Arianne Flemming",
    "title": "COO",
    "companyName": "Informal Systems",
    "talkTitle": "The Next Evolution of Company Management: Distributed Organizations, Funds, and Social Cohesion",
    "talkTime": "10:30-11:00am"
  },
  {
    "name": "Jack Platts",
    "title": "Partner & Advisor",
    "companyName": "Hypersphere",
    "talkTitle": "Tbd",
    "talkTime": "11:00-11:30am",

  },
  {
    "name": "Miguel Hervas",
    "title": "Sr. Software Engineer",
    "companyName": "Centrifuge",
    "talkTitle": "How Centrifuge uses ChainBridge to fuel DeFi and Centrifuge Tinlake",
    "talkTime": "11:30-12:00pm",
  },
  {
    "name": "Stu Peters",
    "title": "Head of Projects",
    "companyName": "ChainSafe",
    "talkTitle": "ChainSafe Communications/A brief overview of Eth2.0 for Beginners",
    "talkTime": "12:00-12:30pm"
  },
  {
    "name": "Joseph Lubin",
    "title": "CEO & Co-Founder of Ethereum",
    "companyName": "ConsenSys",
    "talkTitle": "Fireside Chat with Aidan Hyman",
    "talkTime": "12:30-1:00pm"
  },
  {
    "name": "Colin Schwarz & Priom Chowdhury",
    "title": "ChainSafe Products",
    "companyName": "ChainSafe",
    "talkTitle": "Encrypted File Storage for Everyone: Unveiling ChainSafe Files",
    "talkTime": "1:00-1:30pm"
  },
  {
    "name": "Peter Mauric",
    "title": "Head of Public Affairs",
    "companyName": "Parity Technologies",
    "talkTitle": "Media 3.0’s Masthead",
    "talkTime": "1:30-2:00pm"
  },
  {
    "name": "Praneeth Srikanti",
    "title": "Investment Principal",
    "companyName": "ConsenSys Labs",
    "talkTitle": "Debt-Based Financing",
    "talkTime": "2:00-2:30pm"
  },
  {
    "name": "Juan Benet",
    "title": "CEO & Founder",
    "companyName": "Protocol Labs",
    "talkTitle": "Web3",
    "talkTime": "2:30-3:00pm"
  },
  {
    "name": "Joe Petrowski",
    "title": "Research Analyst",
    "companyName": "Parity Technologies",
    "talkTitle": "Polkadot Governance",
    "talkTime": "3:00-3:30pm"
  },
  {
    "name": "David Hawig",
    "title": "Head of Grants",
    "companyName": "Web3 Foundation",
    "talkTitle": "Introducing Web3 Foundation Grants Program and Polkadot's On-Chain Treasury",
    "talkTime": "3:30-4:00pm"
  },
  {
    "name": "Pooja Shah",
    "title": "Product Lead",
    "companyName": "Protocol Labs",
    "talkTitle": "Tbd",
    "talkTime": "4:00-4:30pm"
  },
  {
    "name": "Mike Goelzer",
    "title": "Product and Strategic Management",
    "companyName": "Protocol Labs",
    "talkTitle": "Architecture of a Browser-based Filecoin Retrieval Market",
    "talkTime": "4:30-5:00pm"
  },
]

export const sortSpeakers: () => object[] = () => {
  const sorted = speakers.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  })
  return sorted;
};