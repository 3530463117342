import React from 'react'
import { Switch, Route } from "@chainsafe/common-components"
import HomePage from "./Pages/HomePage";
import SchedulePage from "./Pages/SchedulePage";
import SpeakersPage from "./Pages/SpeakersPage";
import FaqPage from "./Pages/FaqPage";

export const ROUTE_LINKS = {
  Home: "/",
  Schedule: "/schedule",
  Speakers: "/speakers",
  Faq: "/faq"
}

const Routes = () => {
  return (
    <Switch>
      <Route exact path={ROUTE_LINKS.Home} component={HomePage} />
      <Route exact path={ROUTE_LINKS.Schedule} component={SchedulePage} />
      <Route exact path={ROUTE_LINKS.Speakers} component={SpeakersPage} />
      <Route exact path={ROUTE_LINKS.Faq} component={FaqPage} />
    </Switch>
  )
}

export default Routes