import React from 'react'
import Section from "../Modules/Section";
import { createStyles, ITheme, makeStyles } from "@chainsafe/common-theme";
import { Typography, Grid, Card } from "@chainsafe/common-components";
import { speakers, sortSpeakers } from "./speakerInfo";

const useStyles = makeStyles(({ breakpoints, palette, zIndex, constants }: ITheme) => {

  return createStyles({
    wrapper: {
      maxWidth: "2560px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [breakpoints.up('lg')]: {
        margin: "10vh 0 0 0",
      },
      [breakpoints.up('3800')]: {
        position: "relative",
        left: "50%",
        transform: "translate(-50%)",
      },
    },
    speakerGrid: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      flexGrow: 0, flexShrink: 1,
      maxWidth: "1100px",
      marginTop: constants.generalUnit * 3,
    },
    speaker: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      alignItems: "center",
      '& > p': {
        color: palette.additional["gray"][5],
        fontSize: "20px",
        marginTop: constants.generalUnit,
        lineHeight: "28px",
        maxWidth: "200px",
      },
      '& > span': {
        maxWidth: "150px",
        [breakpoints.down('sm')]: {
          width: "300px",
        }
      },
    },
    speakerImg: {
      width: "140px",
      height: "140px",
    },
    speakerWrapper: {
      margin: "1rem 0", display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "baseline",
    },
    speakerCard: {
      background: "transparent",
      position: "relative",
      border: "none",
      padding: "1rem",
      margin: "1rem",
      minHeight: "200px",
      width: "200px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [breakpoints.down('sm')]: {
        width: "30vw",
        margin: "2rem",
      }
    },
    textContainer: {
      position: "relative",
      zIndex: zIndex?.layer3,
      [breakpoints.down('sm')]: {
        textAlign: "center",
      }
    },
  })
})

const Speakers: React.FC = () => {
  const classes = useStyles();
  sortSpeakers();
  return (
    <div >
      <Section >
        <div className={classes.wrapper} >
          <Grid container xs={12} justifyContent="center" alignItems="center" className={classes.textContainer}>
            <Grid item alignItems="center">
              <Typography variant="h1" id="speakers">Event Speakers</Typography>
            </Grid>
          </Grid>
          <Grid container xs={12} justifyContent="center" alignItems="center" className={classes.speakerGrid}>
            <Grid item alignItems="center" xs={12} className={classes.speakerWrapper}>
              {speakers.map((speaker, index) => (
                <Card className={classes.speakerCard} key={index}>
                  <div className={classes.speaker}>
                    <img src={speaker.headshotUrl} className={classes.speakerImg} alt={speaker.name} />
                    <p>{speaker.name}</p>
                    <p>{speaker.companyName}</p>
                  </div>
                </Card>
              ))}
            </Grid>
          </Grid>
        </div>
      </Section >
    </div>
  )
}

export default Speakers


