import React, { useState } from "react";
import {
  createStyles, ITheme, makeStyles, useMediaQuery
} from "@chainsafe/common-theme";
import { Grid, Typography, useHistory, Link } from "@chainsafe/common-components";
import nav from "./nav.svg"

const useStyles = makeStyles(({ breakpoints, palette, zIndex, constants }: ITheme) => {
  return createStyles({
    container: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      height: constants.generalUnit * 11,
      position: "fixed",
      padding: "1rem",
      zIndex: zIndex?.layer4,
      background: "rgba(14,14,14, 0.3)",
      [breakpoints.down('sm')]: {
        padding: "none",
      }
    },
    linksMenu: {
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      [breakpoints.down('md')]: {
        alignItems: "center",
        justifyContent: "flex-end"
      },

    },
    logo: {
      width: constants.generalUnit * 4,
      height: constants.generalUnit * 4,
      margin: constants.generalUnit,
    },
    brandName: {
      color: palette.common.white.main,
      marginLeft: constants.generalUnit,
    },
    navlink: {
      textDecoration: "none",
      '&:hover': {
        color: palette.additional["gray"][7],
        transition: "ease-in 0.2s"
      },
    },
    link: {
      fontFamily: "vcr",
      textDecoration: "none",
      fontSize: "20px",
      textTransform: "uppercase",
      cursor: "pointer",
      padding: "1rem",
      '&:hover': {
        color: palette.additional["yellow"][4],
        transition: "0.33s ease-in-out",
      },
    },
    menuToggle: {
      [breakpoints.down(1170)]: {
        display: "block",
        cursor: "pointer",
      },
      display: "none",
      width: "32px",
      marginTop: "10px",
      zIndex: 400000,

      height: "32px",
      transition: "transform 0.4s ease-in",
    },
    wrapper: {
      background: "black",
      position: "absolute",
      margin: "-1rem 0",
      left: 0,
      width: "100%",
      height: "100vh",
      zIndex: 400000,
    },
    mobileLinkContainer: {
      zIndex: 1000,
      display: "flex",
      width: "100%",
      marginTop: "20vh",
      height: "auto",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    mobileLinkWrapper: {
      marginTop: "2rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    mobileLink: {
      fontFamily: "vcr",
      textDecoration: "none",
      fontSize: "3rem",
      textTransform: "uppercase",
      color: palette.primary.light,
      '&:hover': {
        color: palette.additional["yellow"][4],
        transition: "0.33s ease-in-out",
      },
      '&:active': {
        color: palette.additional["yellow"][4],
        transition: "0.33s ease-in-out",
      },
    },
  })
})


const NavBar: React.FC = () => {

  const classes = useStyles();

  const [isNavVisible, setIsNavVisible] = useState(false);

  const isMobile = useMediaQuery("(max-width: 1170px)");

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  }
  const { redirect } = useHistory()


  return (
    <nav className={classes.container}>
      <Grid container xs={12}>
        <Grid item justifyContent="center" alignItems="flex-start">
          <Grid container alignItems="center">
            <img className={classes.logo} src="/logos/ChainSafe.png" alt="chainsafe brand logo" />
            <Typography variant="h4" className={classes.brandName}>
              <p>CSCON[0]</p>
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.menuToggle} onClick={toggleNav}>
          <img src={nav} alt="menuIcon" />
        </div>

        {!isMobile && <Grid item className={classes.linksMenu} >
          <a href="#schedule" className={classes.link}>Schedule</a>
          <a href="#speakers" className={classes.link}>Speakers</a>
          <a href="#faq" className={classes.link}>FAQ</a>
          <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/ChainSafeth" className={classes.link}>Twitter</a>
        </Grid>
        }
      </Grid>
      {(isMobile && isNavVisible) && (
        <div className={classes.wrapper}>
          <div className={classes.menuToggle} onClick={toggleNav} style={{ float: "right", margin: "1.55rem 1rem" }}>
            <img src={nav} alt="menuIcon" />
          </div>
          <ul className={classes.mobileLinkContainer}>
            <div className={classes.mobileLinkWrapper} >
              <Link className={classes.mobileLink} onClick={() => redirect('/')}>Home</Link>
            </div>
            <div className={classes.mobileLinkWrapper} >
              <Link className={classes.mobileLink} onClick={() => redirect('/schedule')}>Schedule</Link>
            </div>

            <div className={classes.mobileLinkWrapper} >
              <Link className={classes.mobileLink} onClick={() => redirect('/speakers')}>Speakers</Link>
            </div>
            <div className={classes.mobileLinkWrapper} >
              <Link className={classes.mobileLink} onClick={() => redirect('/faq')}>FAQ</Link>
            </div>
            <div className={classes.mobileLinkWrapper} >
              <a className={classes.mobileLink} target="_blank" rel="noopener noreferrer" href="https://twitter.com/ChainSafeth">Twitter</a>
            </div>
          </ul>
        </div>
      )}
    </nav >
  );
};

export default NavBar
