import React from 'react'
import { createStyles, ITheme, makeStyles } from "@chainsafe/common-theme";
import { Grid } from "@chainsafe/common-components";
import garden from "./svg/garden.svg";


const useStyles = makeStyles(({ breakpoints, palette, zIndex, constants }: ITheme) => {

  return createStyles({
    wrapper: {
      maxWidth: "2560px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [breakpoints.up('3800')]: {
        position: "relative",
        left: "50%",
        transform: "translate(-50%)",
      },
    },
    textContainer: {
      position: "relative",
      zIndex: zIndex?.layer3,
      margin: "4rem",
      [breakpoints.down('sm')]: {
        textAlign: "center",
      }
    },
    footerTitle: {
      fontFamily: "vcr",
      fontSize: "1.6rem",
      color: palette.additional["gray"][3],
      marginBottom: constants.generalUnit * 3,
      textAlign: "center",
    },
    linksWrapper: {
      display: "flex",
      [breakpoints.down('lg')]: {
        flexDirection: "column",
        alignItems: "center",
      },
    },
    footerWrapper: {
      margin: "2rem 0",
      [breakpoints.down('md')]: {
        margin: "1rem 0",
      },
    },
    footerLink: {
      fontFamily: "vcr",
      color: palette.additional["gray"][3],
      marginRight: constants.generalUnit * 5,
      fontSize: "1.25rem",
      cursor: "pointer",
      textDecoration: "none",
      transition: "0.2s color ease-in-out",
      '&:hover': {
        color: palette.additional["gray"][8],
      }
    },
    arrow: {
      color: palette.additional["yellow"][3],
    },
    garden: {
      [breakpoints.down('lg')]: {
        width: "100%",
      },
    },
  })
})

const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <footer style={{ marginTop: "15vh" }}>
      <Grid container xs={12} justifyContent="center" alignItems="center">
        <Grid item justifyContent="center" alignItems="center">
          <h3 className={classes.footerTitle}>Plant the good seed.</h3>
        </Grid>
        <Grid container xs={12} justifyContent="center">
          <ul className={classes.linksWrapper}>
            <li className={classes.footerWrapper}>
              <a href="mailto:support@chainsafe.io" className={classes.footerLink}>
                <span className={classes.arrow}>{">  "}</span>CONTACT US
              </a>
            </li>
            <li className={classes.footerWrapper}>
              <a href="https://chainsafe.io/" target="__blank" rel="noopener noreferrer" className={classes.footerLink}>
                <span className={classes.arrow}>{">  "}</span>LEARN ABOUT CHAINSAFE
              </a>
            </li>
            {/* <li className={classes.footerWrapper}>
              <a href="https://files.chainsafe.io" target="__blank" rel="noopener noreferrer" className={classes.footerLink}>
                <span className={classes.arrow}>{">  "}</span>TRY CHAINSAFE FILES
              </a>
            </li> */}
          </ul>
        </Grid>
        <img className={classes.garden} src={garden} alt="ASCII art of flower garden " />
      </Grid>
    </footer>
  )
}

export default Footer