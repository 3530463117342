import React from "react";
import { ThemeProvider, createTheme } from "@chainsafe/common-theme";
import {
  CssBaseline, Router
} from "@chainsafe/common-components"
import Routes from "./Components/Routes";


const theme = createTheme({
  globalStyling: {
    body: {
      overflowX: "hidden",
      background: "#111",
      fontFamily: "Archivo",

    },
    a: {
      color: "#fafafa",
    }
  },
  themeConfig: {
    typography: {
      h1: {
        fontSize: "72px",
        lineHeight: `80px`,
        color: "#fafafa",
      },
      h2: {
        color: "#fafafa",
      },
      h3: {
        color: "#fafafa",
      },
    },
  }
})

const App: React.FC<{}> = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes />
      </Router>
    </ThemeProvider>
  );
}

export default App;