import React from 'react'
import Section from "../Modules/Section";
import { createStyles, ITheme, makeStyles } from "@chainsafe/common-theme";
import test from "./svg/test.svg"


const useStyles = makeStyles(({ breakpoints }: ITheme) => {

  return createStyles({
    wrapper: {
      maxWidth: "2560px",
      display: "flex",
      paddingTop: "15vh",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [breakpoints.up('xl')]: {
        maxWidth: "none",
      }
    },
    testImg: {
      [breakpoints.down('md')]: {
        width: "100%",
      }
    },
    button: {
      fontSize: "1.25rem",
      marginTop: "2rem",
      "& > a": { textDecoration: "none" },
    },
  })
})

const Landing: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <Section>
        <div className={classes.wrapper}>
          <img src={test} className={classes.testImg} alt="ChainSafe Presents CSCON[0] virual blockchain conference. Dec 2-4 Worlwide. Peers to peers." />
        </div>
      </Section>
    </>
  )
}

export default Landing