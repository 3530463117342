import React from 'react';

import NavBar from "../Modules/NavBar";
import Landing from "../Subpages/Landing";
import About from "../Subpages/About";
import Speakers from "../Subpages/Speakers";
import Schedule from "../Subpages/Schedule";
import Faq from "../Subpages/Faq";
import Footer from "../Subpages/Footer";


const HomePage: React.FC = () => {

  return (
    <div>
      <NavBar />
      <Landing />
      <About />
      <Schedule />
      <Speakers />
      <Faq />
      <Footer />
    </div >
  );
}

export default HomePage;
