import React from 'react';
import NavBar from "../Modules/NavBar";
import Speakers from "../Subpages/Speakers";
import Footer from "../Subpages/Footer";
import HomeLink from "../Modules/HomeLink";


const SpeakersPage: React.FC = () => {

  return (
    <div>
      <NavBar />
      <div style={{ height: "10vh" }}></div>
      <Speakers />
      <HomeLink />
      <Footer />
    </div >
  );
}

export default SpeakersPage;
