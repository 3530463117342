import React from 'react';
import NavBar from "../Modules/NavBar";
import Schedule from "../Subpages/Schedule";
import Footer from "../Subpages/Footer";
import HomeLink from "../Modules/HomeLink";



const SchedulePage: React.FC = () => {
  return (
    <>
      <NavBar />
      <Schedule />
      <HomeLink />
      <Footer />
    </>
  );
}

export default SchedulePage;
