import React from 'react';
import NavBar from "../Modules/NavBar";
import Footer from "../Subpages/Footer";
import HomeLink from "../Modules/HomeLink";
import Faq from "../Subpages/Faq";



const FaqPage: React.FC = () => {
  return (
    <>
      <NavBar />
      <Faq />
      <div style={{ height: "10vh" }}></div>
      <HomeLink />
      <Footer />
    </>
  );
}

export default FaqPage;
