import React from 'react'
import Section from "../Modules/Section";
import { createStyles, ITheme, makeStyles } from "@chainsafe/common-theme";
import { Typography, Grid } from "@chainsafe/common-components";
import underline from "./svg/shortunderline.svg";
import ExpansionPanel from "../Modules/ExpansionPanel";



const useStyles = makeStyles(({ breakpoints, palette, zIndex, constants }: ITheme) => {

  return createStyles({
    container: {
      maxWidth: "2560px",
      margin: "0 10%",
      paddingTop: "2rem",
      display: "flex",
      justifyContent: "center",
      [breakpoints.up('lg')]: {
        paddingTop: "10vh",
        paddingBottom: "5vh",
      },
      [breakpoints.up('xl')]: {
        paddingTop: "5vh",
        maxWidth: "2560px",
        position: "relative",
        left: "40%",
        transform: "translate(-50%)",
      },
      [breakpoints.down('sm')]: {
        maxWidth: "100%",
        margin: 0,
      },
    },
    wrapper: {
      maxWidth: "2560px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    panelWrapper: {
      display: "flex",
      flex: "1 0 auto",
      [breakpoints.up('xl')]: {
        flexDirection: "column",
        alignItems: "baseline",
        justifyContent: "center",
      },
    },
    textContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: zIndex?.layer3,
      margin: "4rem",
      [breakpoints.down('sm')]: {
        textAlign: "center",
      },
      [breakpoints.up('xl')]: {
        alignItems: "center",
      },
    },
    faqAnswerText: {
      margin: "0 5%",
      fontSize: "1.1rem",
      lineHeight: "1.6rem",
      color: palette.additional["gray"][5],
      [breakpoints.up('md')]: {
        maxWidth: "60%",
      },
      [breakpoints.up('xl')]: {
        maxWidth: "600px",
        fontSize: "1.25rem",
        lineHeight: "1.7rem",
        margin: "0",
      },
    },

  })
})

const Faq: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container} id="faq">
      <Section >
        <div >
          <Grid container xs={12} justifyContent="center" alignItems="center" className={classes.wrapper}>
            <Grid item xs={12} lg={4} xl={12} className={classes.textContainer}>
              <Typography variant="h1" id="speakers">FAQ</Typography>
              <img src={underline} style={{ width: "130px" }} alt="Decorative underline" />
            </Grid>
            <Grid item className={classes.panelWrapper}>
              <ExpansionPanel landingVariant="faq" header="How do I network?" variant="borderless">
                <p className={classes.faqAnswerText}>Virtual networking is available to anyone who registers for the event. It will take place from the same page the live stream is broadcasted from. Simply take a seat at a themed table to meet future employers, employees and friends! </p>
              </ExpansionPanel>
              <ExpansionPanel landingVariant="faq" header="How much does it cost?" variant="borderless">
                <p className={classes.faqAnswerText}>CSCON is completely free to join.</p>
              </ExpansionPanel>
              <ExpansionPanel landingVariant="faq" header="How do I attend?" variant="borderless">
                <p className={classes.faqAnswerText}>To attend the event, you need to <a href="https://live.remo.co/e/cscon0-1" target="__blank" rel="noopener noreferrer">register for a free ticket</a>. This event uses Remo to livestream and enable live networking. To watch a livestream, visit the event page at the date and time of the broadast. To network, seat yourself at a table on the conference's event page to connect to other attendees.</p>
              </ExpansionPanel>
              <ExpansionPanel landingVariant="faq" header="What if I miss or can't attend livestreams?" variant="borderless">
                <p className={classes.faqAnswerText}>Most broadcasts will be recorded for later viewing on YouTube.</p>
              </ExpansionPanel>
            </Grid>
          </Grid>
        </div>
      </Section >
    </div>
  )
}

export default Faq

