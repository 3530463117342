import React from "react";
import { createStyles, makeStyles } from "@chainsafe/common-theme";



const useStyles = makeStyles(() => {

  return createStyles({
    root: {
      display: "flex"
    },
    link: {
      padding: "0",
      margin: "0 auto",
      fontFamily: "vcr",
      fontSize: "1.1rem",
      position: "relative",
    }
  })
})

const HomeLink: React.FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <a href="/" className={classes.link}>Back to home</a>
    </div>
  );
}

export default HomeLink;
